import React, { lazy, Suspense } from 'react';

const IntroTextWordpressProblemCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'IntroTextWordpressProblemCChLazy' */ './IntroTextWordpressProblemCCh'));

function IntroTextWordpressProblemCChLazyLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading IntroTextWordpressProblemCCh ...</div>}>
                <IntroTextWordpressProblemCCh {...props} />
            </Suspense>
        </div>
    );
}

export default IntroTextWordpressProblemCChLazyLazy;